import "../styles/index.scss";

const submitButton = document.querySelector(".submit");
const responseMessage = document.querySelector(".response_msg");
const userIdField = document.querySelector("input[name='user_id']");
const userIdGroup = userIdField.closest(".input_group");
const passwordField = document.querySelector("input[name='password']");
const passwordGroup = passwordField.closest(".input_group");
window.locationHref = window.location.href;

function submit(user_id, password) {
  userIdGroup.classList.remove("error");
  passwordGroup.classList.remove("error");
  responseMessage.classList.remove("error");

  fetch(window.locationHref, {
    method: "POST",
    headers: {
      "content-type": "application/json"
    },
    body: JSON.stringify({ user_id: user_id, password: password })
  }).then(function(res) {
    if (res.ok) {
      res.text().then(function(html) {
        document.querySelector("html").innerHTML = html;
        setTimeout(function() {
          document.redirect.submit();
        }, 0);
      });
    }
    else {
      res.json().then(function(json) {
        if (window.onLoginError) {
          window.onLoginError(json, res.status);
        } else {
          if (!json) {
            responseMessage.innerHTML = genericError;
            responseMessage.classList.add("error");
          }
          switch (json.code) {
            case 400.01:
            case 400.02:
            case 400.03:
              userIdGroup.classList.add("error");
              passwordGroup.classList.add("error");
              break;
            default:
              responseMessage.innerHTML = genericError;
              responseMessage.classList.add("error");
          }
        }
      }).catch(function() {
        if (window.onLoginError) {
          window.onLoginError(undefined, res.status);
        } else {
          responseMessage.innerHTML = genericError;
          responseMessage.classList.add("error");
        }
      });
    }
  }).catch(function() {
    if (window.onLoginError) {
      window.onLoginError(undefined, res.status);
    } else {
      responseMessage.innerHTML = genericError;
      responseMessage.classList.add("error");
    }
  });
}

function onSubmit(e) {
  const userId = userIdField.value;
  const password = passwordField.value;
  let error = false;

  // validate email
  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userId)) {
    userIdGroup.classList.add('error');
    error = true;
  } else {
    userIdGroup.classList.remove('error');
  }

  // validate confirmation number
  if (!/^[0-9A-Z]{8,}$/.test(password)) {
    passwordGroup.classList.add('error');
    error = true;
  } else {
    passwordGroup.classList.remove('error');
  }

  if (error) {
    e.preventDefault();
    return;
  }

  if (window.onLoginStart) {
    window.onLoginStart(userId, password)
      .then(function() {
        submit(userId, password);
      }).catch(function() {});
  } else {
    submit(userId, password);
  }
}

submitButton.addEventListener("click", onSubmit);
submitButton.addEventListener("touch", onSubmit);

userIdField.addEventListener("keypress", function (e) {
  if (e.key == "Enter")
    {passwordField.focus();}
});
passwordField.addEventListener("keypress", function (e) {
  if (e.key == "Enter")
    {submitButton.click();}
});
